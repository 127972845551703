@import "..\Autobopr\Assets\functions.less";

.header {
	&__logo {
		animation-name:none;
		@media @tablet {
			left: 39%;
			width: 24.16%;
		}
	}
}

.featured-categories {
    &__col {
        margin-bottom: 60px;
    }
}

.category-links {
    &__link {
        &:nth-child(6n+1) {
            //red
            background-color: #f40000;
            background: linear-gradient(to bottom,#f40000,#eb0000);
        }
        &:nth-child(6n+2) {
            //orange
            background-color: #f57a33;
            background: linear-gradient(to bottom, #f57a33, #d96c2e);
        }
        &:nth-child(6n+3) {
            //yellow
            background-color: #edba2f;
            background: linear-gradient(to bottom, #edba2f, #cfa32b);
        }
        &:nth-child(6n+4) {
            //green
            background-color: #16c939;
            background: linear-gradient(to bottom, #16c939, #108f29);
        }
        &:nth-child(6n+5) {
            //blue
            background-color: #1175e1;
            background: linear-gradient(to bottom,#1175e1,#0940c9);
        }
        &:nth-child(6n+6) {
            //purple
            background-color: #a015d6;
            background: linear-gradient(to bottom, #a015d6, #700f96);
        }
    }
}

@media (min-width: 768px) {
    .header__logo {
        left: 35%;
        width: 30.16%;
    }
}
// #ctl00_ctl00_HeaderLogo{
//     max-width:229px !important;
//     max-height:280px !important;
// }

// .header__logo{
//     animation-name:none;
//     display:none;
// }

// .banner__left{
//     display:none;
// }
